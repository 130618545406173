import { useOrderFormStore } from '@/stores/forms/orderFormStore';

import useMerchApiEndpoints from '@/composables/api/useMerchApiEndpoints';
import useAutomaticRedirect from '@/composables/helpers/automaticRedirect';

const handleApiRedirect = async (routerRef) => {
  const orderStore = useOrderFormStore();
  const { getApiOrder, getPAPIOrder } = useMerchApiEndpoints();
  const { redirectForApiOrders } = useAutomaticRedirect();

  const isAnApiOrder = () => {
    const params = new URLSearchParams(window.location.search);
    return params.has('id') || params.has('initId');
  };

  const isPapiOrder = () => {
    const params = new URLSearchParams(window.location.search);
    return params.has('initId');
  };

  if (isAnApiOrder()) {
    const merchRes = await (isPapiOrder()
    ? getPAPIOrder()
    : getApiOrder());

    orderStore.setMerchApiData(merchRes.data);
    const isAnApplePayOrder = await orderStore.checkUserApplePaySession();

    if ((!!isAnApplePayOrder && orderStore.canSkipOrderPageFromApiData)
      || orderStore.canSkipOrderPageFromApiData
    ) {
      await redirectForApiOrders(routerRef);
    }
  }
};

export {
	handleApiRedirect,
};
