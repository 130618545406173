import timeDifference from '../helpers/useTimeHelpers';
import useLocalStorage from './useLocalStorage';

export default () => {
  const { bindLocalStorage } = useLocalStorage();

  const removeKeysWithinObject = () => {
    const keysToRemoveWithinObj = [
      'global.payments',
      'global.nftPayments',
      'global.payouts',
      'global.countries',
      'global.subdivisions',
    ];

    keysToRemoveWithinObj.forEach((el) => {
      const [firstObj, keyToRemove] = el.split('.');
      const shallowCopy = JSON.parse(localStorage.getItem(firstObj));

      if (shallowCopy && Object.prototype.hasOwnProperty.call(shallowCopy, keyToRemove)) {
        delete shallowCopy[keyToRemove];
        bindLocalStorage(firstObj, shallowCopy);
      }
    });
  };

  const clearLocalStorage = () => {
    const keysToRemove = [
      'token',
      'buyOrder',
      'coinData',
      'bundleId',
      'timestamp',
      'isAxnab',
      'identityRoutes',
      'identityProgress',
      'currentIdentityView',
      'queueCounter',
      'totalSteps',
    ];

    keysToRemove.forEach((el) => {
      localStorage.removeItem(el);
    });

    removeKeysWithinObject();
  };

  const refreshLocalStorage = () => {
    const timestamp = localStorage.getItem('timestamp');
    const bundleId = localStorage.getItem('bundleId');
    const scriptList = document.querySelectorAll('script[crossorigin]');

    const currentBundle = scriptList[scriptList.length - 1].src.split('.');
    const currentBundleId = currentBundle[currentBundle.length - 2];

    const date = new Date();

    if (timestamp) {
      const timeDiff = timeDifference(date.getTime(), timestamp);

      if (timeDiff.minutes >= 60) {
        clearLocalStorage();
      }
    }

    if (bundleId !== currentBundleId) {
      clearLocalStorage();
    }

    if (!bundleId || typeof bundleId === 'undefined') {
      localStorage.setItem('bundleId', currentBundleId);
    }

    if (!timestamp || typeof timestamp === 'undefined') {
      localStorage.setItem('timestamp', date.getTime());
    }
  };

  return {
    refreshLocalStorage,
  };
};
