/* eslint-disable import/no-cycle */
/* eslint-disable import/prefer-default-export */
import { defineStore } from 'pinia';
import { isBoolean } from '@vueuse/core';
import { getCountries } from '@/composables/api/useCountryEndpoints';
import useLocalStorage from '@/composables/storage/useLocalStorage';
import { useOrderFormStore } from '@/stores/forms/orderFormStore';
import usePaymentEndpoints from '@/composables/api/usePaymentEndpoints';
import usePaymentFilters from '@/composables/filters/usePaymentFilters';

const { checkLocalStorage, bindLocalStorage } = useLocalStorage();
const { getPayments } = usePaymentEndpoints();
const { removeApplepayByUserSession } = usePaymentFilters();

export const ORDER_TYPE = {
  BUY: 'BUY',
  SELL: 'SELL',
};

export const globalOptions = {
  payments: [],
  payouts: [],
  nftPayments: [],
  countries: [],
  subdivisions: [],
  customerId: '',
  customerReference: '',
  showSettings: false,
  theme: null,
  merchant: null,
  isFormLoading: false, // TODO: to be generated by API probably,
  detectedGeo: null,
};

export const useGlobalStore = defineStore({
  id: 'global',
  state: () => ({
    global: checkLocalStorage('global', globalOptions),
    fetchingToken: false,
    token: null,
    sessionToken: null,
    expressCheckoutEnabled: false,
    // previous feature HE-1074 is setting locale item without
    // quotations causing issues with checkLocalStorage()'s JSON.parse()
    language: localStorage.getItem('locale'),
  }),
  actions: {
    getSessionToken() {
      const storedToken = checkLocalStorage('token', false);

      if (storedToken) {
        this.sessionToken = storedToken;
      }
    },
    setCountries(countries) {
      this.global.countries = countries;
    },
    setSubdivisions(subdivisions) {
      this.global.subdivisions = subdivisions;
    },
    setLanguage(language) {
      this.language = language;
      bindLocalStorage('locale', this.language);
    },
    setIsFormLoading(isLoading) {
      this.global.isFormLoading = isLoading;
    },
    setMerchantSettings(settings) {
      const orderStore = useOrderFormStore();
      this.global.merchant = settings;
      const { defaultCoin } = this.global.merchant;

      if (defaultCoin) orderStore.updateDefaultCoin(defaultCoin);
    },
    setExpressCheckoutEnabled(value = false) {
      this.expressCheckoutEnabled = value;
    },
    toggleSettings(value) {
      if (isBoolean(value)) {
        this.global.showSettings = value;
      }
      if (value === undefined) {
        this.global.showSettings = Boolean(!this.global.showSettings);
      }
      bindLocalStorage(this.$id, this.global);
    },
    toggleTheme() {
      // eslint-disable-next-line no-nested-ternary
      const newTheme = this.global.theme === undefined
          ? 'DARK'
          : this.global.theme === 'LIGHT'
          ? 'DARK'
          : 'LIGHT';
      this.global.theme = newTheme;
      bindLocalStorage(this.$id, this.global);
    },
    setTheme(theme) {
      this.global.theme = theme;
      bindLocalStorage(this.$id, this.global);
    },
    async fetchCountries() {
      const res = await getCountries();
      const countries = res.data.data.countries.map((country) => {
        // once HE-4830 and HE-4831 have been deployed, we can remove this first condition
        if (Array.isArray(country)) {
          return {
            name: country[0],
            code: country[1].toUpperCase(),
            collectSubdivision: 0,
          };
        }
        return {
          name: country.name,
          code: country.code.toUpperCase(),
          collectSubdivision: country.collectSubdivision,
        };
      });

      // eslint-disable-next-line no-nested-ternary,vue/max-len
      this.global.countries = countries.sort((itemA, itemB) => (itemA.name < itemB.name ? -1 : itemA.name > itemB.name ? 1 : 0));
    },

    async fetchPayments() {
      const orderStore = useOrderFormStore();

      const res = await getPayments();

      const { payment_methods, payouts, nft_payments } = res.data.data;
      if (payment_methods) {
        const filteredPayments = removeApplepayByUserSession(payment_methods);
        this.global.payments = filteredPayments;

        if (!payouts) {
          orderStore.setActiveMode('BUY');
        }
      }

      if (payouts) {
        if (!payment_methods) {
          orderStore.setActiveMode('SELL');
        }
        this.global.payouts = payouts;
      }

      if (nft_payments) {
        const filterdNFTPayments = removeApplepayByUserSession(nft_payments);
        this.global.nftPayments = filterdNFTPayments;
      }
      bindLocalStorage(this.$id, this.global);
    },
    setCustomerId(customerId) {
      this.global.customerId = customerId;
      bindLocalStorage(this.$id, this.global);
    },
    setCustomerReference(customerReference) {
      this.global.customerReference = customerReference;
      bindLocalStorage(this.$id, this.global);
    },
    setDetectedGeo(geo) {
      this.global.detectedGeo = geo;
      bindLocalStorage(this.$id, this.global);
    },
    setDefaultFiat(fiat) {
      this.global.defaultFiat = fiat;
      bindLocalStorage(this.$id, this.global);
    },
  },
  getters: {
    getPayments: (store) => store.global.payments,
    getPayouts: (store) => store.global.payouts,
    getNFTPayments: (store) => store.global.nftPayments,
    getCountries: (store) => store.global.countries,
    getSubdivisions: (store) => store.global.subdivisions,
    getCountryCode: (store) => (countryName) => {
      const findMatchingCountry = store.global.countries.find(
        (country) => country.name === countryName,
      );
      if (!findMatchingCountry) {
        return countryName;
      }

      return findMatchingCountry.code;
    },
    getCountryName: (store) => (countryCode) => {
      const findMatchingCountry = store.global.countries.find(
        (country) => country.code === countryCode,
      );
      if (!findMatchingCountry) {
        return countryCode;
      }

      return findMatchingCountry.name;
    },
    getIsLightMode: (store) => store.global.theme === 'LIGHT',
    getIsDarkMode: (store) => store.global.theme === 'DARK',
    getIsThemeSetted: (store) => store.global.theme !== null,
    getCustomerId: (store) => store.global.customerId,
    getCustomerReference: (store) => store.global.customerReference,
    getMerchantSettings: (store) => store.global.merchant,
    getIsFormLoading: (store) => store.global.isFormLoading,
    getIsInIFrame: () => {
      try {
        return window.self !== window.top;
      } catch (e) {
        return true;
      }
    },
    getIsGreyLabel: (store) => !!(store.global.merchant?.theme?.hide_logo === true),
    getDetectedGeo: (store) => store.global.detectedGeo,
    getIsAutoRedirectToMerchant:
      (store) => store.global.merchant?.shouldAutoRedirectToMerchant === true,
  },
});
