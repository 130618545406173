<template>
  <Transition name="hideSplash">
    <div class="splash" v-if="showSplashLoader">
      <div class="splash__content">
        <LogoBanxa />
        <Loader />
        <Transition name="hideSplash">
          <p class="splash__message" v-if="loadingMessage" v-html="loadingMessage" />
        </Transition>
      </div>
    </div>
  </Transition>
</template>
<script setup>
import {
 ref, computed, watch, onMounted,
} from 'vue';
import { useRoute } from 'vue-router';
import { useGlobalStore } from '@/stores/globalStore';
import LogoBanxa from '@/components/images/LogoBanxa.vue';
import Loader from '@/components/icons/Loader.vue';

const store = useGlobalStore();
const route = useRoute();

let routeChangeTimeout = null;
let splashInterval = null;

const merchantSettingLoaded = ref(false);
const routeSettled = ref(false);
const serverError = ref(false);
const splashVisibleTime = ref(0);
const loadingMessage = ref('');

const merchSettings = computed(() => store.global.merchant);
const showSplashLoader = computed(
  () => !(
    serverError.value
    || (merchantSettingLoaded.value === true && routeSettled.value === true)),
);

/**
 * Reset splash timer if the route changes
 *
 * To prevent the splash screen from disappearing too quickly, we buffer the splash screen
 * for a few seconds until the router has settled on a new route. This function resets the
 * timer if the route changes before the timer has resolved.
 *
 * @returns {void}
 */
 const bufferSplashOnRoute = () => {
  const DELAY_MS = 1750;
  if (routeChangeTimeout) { clearTimeout(routeChangeTimeout); }
  routeChangeTimeout = setTimeout(() => {
    routeSettled.value = true;
  }, DELAY_MS);
};

const handleLoadingMessage = (sec) => {
  switch (true) {
    case (sec >= 30):
      return 'We\'re having trouble loading your experience.<br/>If this continues, please refresh the page.';
    case (sec >= 29):
      return ''; // Resetting to empty string to prevent flickering
    case (sec >= 15):
      return 'This is taking a bit longer than expected<br/>Please wait...';
    case (sec >= 14):
      return ''; // Resetting to empty string to prevent flickering
    case (sec >= 5):
      return 'Loading your experience';
    default:
      return '';
  }
};

const startSplashTimer = () => {
  splashInterval = setInterval(() => {
    splashVisibleTime.value += 1;
    loadingMessage.value = handleLoadingMessage(splashVisibleTime.value);
  }, 1000);
};

watch(() => route.path, () => {
  if (route.path === '/error') {
    serverError.value = true;
  }
  bufferSplashOnRoute();
});

watch(merchSettings, (newVal) => {
  merchantSettingLoaded.value = Boolean(newVal?.name);
});

onMounted(() => {
  startSplashTimer();
  bufferSplashOnRoute();
});

</script>
<style scoped lang="scss">
.splash {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: var(--background);
  z-index: 20;
  display: grid;
  justify-items: center;
  align-items: center;

  &__content {
    display: flex;
    gap: 0.8rem;
    position: relative;

    .logo {
      max-width: 16rem;
      align-self: center;
    }

    p {
      display: block;
      position: absolute;
      left: 50%;
      transform: translateX(-50%) translateY(3rem);
      white-space: nowrap;
      text-align: center;
      color: hsla(0, 0%, 100%, 0.9);
    }
  }
}

.loader {
  height: 2.4rem;
  width: 2.4rem;
  position: absolute;
  top: -0.4rem;
  right: 0;
  transform: translateX(calc(100% + 0.8rem));
}

.hideSplash {
  &-enter-active,
  &-leave-active {
    transition: opacity 500ms ease-in-out;
  }

  &-enter-from,
  &-leave-to {
    opacity: 0;
  }
}

</style>
