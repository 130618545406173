<template>
  <div class="app" :class="centerLayoutClass">
    <SettingsOpenButton />
    <!-- <Debug /> -->
    <UkStickyHeader />

    <NFTMainBackground v-if="orderStore.isNFTMode" />
    <MainBackground v-else-if="!isWidget" />
    <MainSidebar v-if="!isWidget" />
    <MainBanner
      v-if="showBanner"
      :banner="getNotificationBanner"
      @onButtonClick="notificationStore.dismissBanner(route.name)"
    />

    <div class="content">
      <MainContent>
        <RouterView v-slot="{ Component }">
          <Transition name="fade" mode="out-in">
            <component :is="Component" />
          </Transition>
        </RouterView>

        <PoweredFooter v-if="!isWidget" />
      </MainContent>
      <MainSettingSidebar v-if="!isWidget" />
    </div>
    <MainModal v-if="modalStore.showModal" />
    <Snackbar />
    <ToastManager />
  </div>
  <SplashLoader />
</template>

<script setup>
import {
  watch, onMounted, provide, ref, toRef, computed, defineAsyncComponent,
} from 'vue';
import { useEventListener, whenever } from '@vueuse/core';
import { useRoute, RouterView, useRouter } from 'vue-router';
import axios from 'axios';

import { useGlobalStore } from '@/stores/globalStore';
import { useOrderFormStore } from '@/stores/forms/orderFormStore';
import { useNotificationStore } from '@/stores/components/notificationStore';
import { useUkStore } from '@/stores/custom/UkStore';
import { useModalStore } from '@/stores/components/modalStore';

import useTheme from '@/composables/ui/useTheme';
import useThirdPartyScripts from '@/composables/api/useThirdPartyScripts';
import useMerchantSettings from '@/composables/ui/useMerchantSettings';
import useLanguageSnackbar from '@/composables/ui/useLanguageSnackbar';
import useAxnab from '@/composables/identity/useAxnab';

import { currencyFromCountry } from '@/composables/helpers/countryCurrency';
import ToastManager from '@/components/ui/alerts/ToastManager.vue';
import SplashLoader from './components/layout/SplashLoader.vue';

const MainContent = defineAsyncComponent(() => import('@/components/layout/MainContent.vue'));
const MainSettingSidebar = defineAsyncComponent(() => import('@/components/layout/MainSettingSidebar.vue'));
const MainBanner = defineAsyncComponent(() => import('@/components/ui/alerts/MainBanner.vue'));
const MainModal = defineAsyncComponent(() => import('@/components/ui/modals/MainModal.vue'));
const Snackbar = defineAsyncComponent(() => import('@/components/ui/alerts/Snackbar.vue'));
const SettingsOpenButton = defineAsyncComponent(() => import('@/components/ui/buttons/SettingsOpenButton.vue'));
const UkStickyHeader = defineAsyncComponent(() => import('@/components/ui/alerts/UkStickyHeader.vue'));
const PoweredFooter = defineAsyncComponent(() => import('@/components/layout/PoweredFooter.vue'));
const MainBackground = defineAsyncComponent(() => import('@/components/layout/MainBackground.vue'));
const NFTMainBackground = defineAsyncComponent(() => import('@/components/layout/NftMainBackground.vue'));
const MainSidebar = defineAsyncComponent(() => import('@/components/layout/MainSidebar.vue'));

const { useSiftScript } = useThirdPartyScripts();

const { updateThemeFromQuery, updateAppTheme } = useTheme();
const { getMerchantSettings, getNotifications } = useMerchantSettings();
const { detectedCountryCode, handleGeolocationSnackbar } = useLanguageSnackbar();
const { getAxnabSwapSession } = useAxnab();
const globalStore = useGlobalStore();
const orderStore = useOrderFormStore();
const notificationStore = useNotificationStore();
const ukStore = useUkStore();
const modalStore = useModalStore();
const route = useRoute();
const router = useRouter();

const appScriptsLoaded = ref(false);
const provideUiDisable = ref(false);
const ukBannerHeight = toRef(ukStore, 'bannerHeight');

const isNftMobile = ref(orderStore.isNFTMode && window.innerWidth < 640);
const isWidget = computed(() => route?.name && route.name.includes('widget'));
provide('isDisabledByKey', provideUiDisable);

useEventListener(window, 'resize', () => {
  isNftMobile.value = orderStore.isNFTMode && window.innerWidth < 640;
});

const showBanner = computed(() => {
  if (!route.name) { return false; }

  const isOrderPage = route.name.includes('order');
  const isPaymentPage = route.name.includes('payment');
  const isSummaryPage = route.name.includes('summary');

  if (isOrderPage || isPaymentPage) {
    return notificationStore.getBannerData.order?.toShow;
  }

  if (isSummaryPage) {
    return notificationStore.getBannerData.orderStatus?.toShow;
  }

  return false;
});

const getNotificationBanner = computed(() => {
  if (route?.name && route.name.includes('order')) {
    return notificationStore.getBannerData.order;
  }

  if (route?.name && route.name.includes('payment')) {
    return notificationStore.getBannerData.order;
  }

  if (route?.name && route.name.includes('summary')) {
    return notificationStore.getBannerData.orderStatus;
  }

  return {};
});

const bindGeoToStore = (resp) => {
  globalStore.setDetectedGeo(resp.data);
};

const fetchUsersGeo = () => axios.get('https://get.geojs.io/v1/ip/geo.json').then((resp) => {
    bindGeoToStore(resp);
    return resp;
  });

const callAppScripts = async () => {
  await Promise.allSettled([
    useSiftScript(),
    getMerchantSettings(route),
    getNotifications(),
  ]);
  appScriptsLoaded.value = true;
};

onMounted(async () => {
  updateAppTheme(route.query);
  await callAppScripts();
  try {
    await fetchUsersGeo()
    .then((geo) => {
      const currency = currencyFromCountry(geo.data.country_code);
      globalStore.setDefaultFiat(currency);
    });
  } catch (err) {
    console.error(`${err} – Error fetching user's geo location.`);
  }
});

watch(
  () => route.query.theme,
  (newTheme) => {
    if (!newTheme) {
      return;
    }

    updateThemeFromQuery(newTheme);
  },
  { deep: true },
);

watch(
  () => route.name,
  () => {
    if (!route.name) return;
    if (route.name.includes('nft')) {
      orderStore.setActiveMode('NFT-BUY');
    }
  },
  { immediate: true },
);

whenever(
  () => route.name
  && route.name === 'identity.view'
  && route.query?.session
  && appScriptsLoaded.value,
  async (triggerForAxnabIdentitySession) => {
    if (!triggerForAxnabIdentitySession) return;

    const identityRoute = '/identity/';
    await getAxnabSwapSession(identityRoute, router);
  },
);

watch(
  () => route,
  (val) => {
    if (val && route.name === '/order') {
      localStorage.removeItem('token');
    }
  },
  { immediate: true },
);

// to allow OrderView to be mounted
setTimeout(() => {
  watch(
    () => detectedCountryCode,
    (toTrigger) => {
      if (!toTrigger) {
        return;
      }

      handleGeolocationSnackbar();
    },
  );
}, 1000);

const centerLayoutClass = computed(() => (globalStore.getIsGreyLabel || orderStore.isNFTMode ? 'center-layout' : ''));
</script>

<style lang="scss">
.app {
  width: 100%;
  min-height: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  margin-top: v-bind(ukBannerHeight);
  position: relative;

  @include breakpoint($min-m) {
    height: 100%;
    flex-direction: row;
    overflow: hidden;
  }
}

.app.center-layout {
  width: 100%;
  min-height: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: visible;
}

.content {
  flex: 1;
  display: flex;
  flex-direction: column;
}
</style>
